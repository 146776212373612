.canvasContainer {
  image-rendering: optimizeSpeed;             /* Older versions of FF          */
  image-rendering: -moz-crisp-edges;          /* FF 6.0+                       */
  image-rendering: -webkit-optimize-contrast; /* Safari                        */
  image-rendering: -o-crisp-edges;            /* OS X & Windows Opera (12.02+) */
  image-rendering: pixelated;                 /* Awesome future-browsers       */
  -ms-interpolation-mode: nearest-neighbor;   /* IE                            */
  overflow: visible;
  display: flex;
}

.canvasEl {
  width: 100%;
  height: 100%;
  overflow: visible;
}

.canvasEl:hover {
  cursor: zoom-in;
}