.pickerHighLevelContainer {
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 3px 12px rgba(0,0,0,0.15);
  position: fixed;
  bottom: 30px;
  align-items: center;
  left: 50%;
  transform: translate(-50%, 0);
}

.colorPickerContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;
  width: 225px;
  padding: 5px 0px 5px 5px;
}

.colorPickerElement {
  width: 25px;
  height: 25px;
}

.colorPickerElementWhite {
  border: 1px solid #DDDDDD;  
}

.colorPickerElement:hover, .colorPickerSelectedElement {
  width: 25px;
  height: 25px;
  z-index: 2;
  outline: 2px solid #ffffff;
  box-shadow: 0 0 5px 2px rgba(0,0,0,0.25);
}

.zoomButtonContainer {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.zoomButton {
  width: 100%;
  height: 100%;
}