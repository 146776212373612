.galleryElementContainer {
  margin: 0 auto;
  text-align: center;
  min-height: 18vw;
  max-height: 20vw;
  min-width: 18vw;
  max-width: 20vw;
}


.galleryElementContainerMobile {
  text-align: center;
  width: 100%;
  height: auto;
  padding-bottom: 30px;
}


.galleryElementImageContainer {
  width: 100%;
  height: 70%;
  justify-content: center;
  align-items: center;
}

.galleryElementImage {
  width: 100%;
  height: 100%;
}

.galleryElementTitle {
  color: black;
  font-weight: 700;
  text-align: center;
  font-size: larger;
}

.galleryElementTitleContainer {
  width: 100%;
  height: 1.8vw;
  max-height: 2vw;
  padding: 5px;
}

.galleryInProgress {
  color: white;
  font-weight: 700;
  font-size: xx-large;
}

.galleryContainer {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 0 auto;
  flex-wrap: wrap;
}

.galleryContainerMobile {
  display: flex;
  flex-direction: column;
  width: 75%;
  justify-content: center;
  align-items: center;
  margin: auto;
}